.react-portal-hint__body{
    white-space: pre !important;
    font-size: 1.2rem;
    line-height: 1.5rem;
}

/*.batteryHover{*/
/*    !*width: 30px;*!*/
/*    position: relative;*/
/*    left: 0px;*/
/*    bottom: 0px;*/
/*    transition: 1s;*/
/*    !*background-color: #689F38;*!*/
/*    !*color: white;*!*/
/*    font-weight: bold;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    display: inline-block;*/
/*}*/

.backgroundBar:hover, .backgroundBar:active {
    left: 00px;
    transition: 1s;
    /*display: inline-block;*/

}

.backgroundBar:hover~.notMoveable, .backgroundBar:active~.notMoveable {
    left: 30px;
    transition: 1s;
    position: relative;
    /*display: inline-block;*/

}

.backgroundBar~.notMoveable {
    left: 0px;
    transition: 1s;
    position: relative;
    /*display: inline-block;*/

}
/*.notMoveable:hover{*/
/*    !*left: -30px;*!*/
/*    position: relative;*/
/*    transition: 1s*/
/*}*/
/*.notMoveable{*/
/*    position: relative;*/
/*    !*display: inline-block;*!*/

/*}*/

.backgroundBar{
    width: 30px;
    position: absolute;
    left: -30px;
    bottom: 0px;
    top: 0px;
    transition: 1s;
    background-color: #689F38;
    color: white;
    font-weight: bold;
    /*display: inline-block;*/
}

.btn-primary{
    color: gray;
    background-color: white;
    border-color: white;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active{
    color: black;
    background-color: rgba(5, 48, 97,0.25);
    border-color: #084892;
}


.show>.btn-primary.dropdown-toggle{
    color: black;
    background-color: rgba(8, 72, 146,0.25);
    border-color: #837d06;
    box-shadow: 0 0 0 0.2rem rgb(8, 72, 146)
}

.show > .btn-primary.dropdown-toggle:focus{
    box-shadow: 0 0 0 0.2rem rgb(8, 72, 146)

}

.btn-primary:focus {
    color: black;
    background-color: rgba(5, 48, 97, 0.25);
    border-color: #084892;
    box-shadow: 0 0 0 0.2rem rgb(8, 72, 146);
}

.dropdown-item:active, .dropdown-item.active{
    background-color: #084892;
}

.MuiDialog-paperScrollPaper{
    display: unset !important;
    flex-direction: unset !important;
    position: unset !important;
}

.notification-success {
    background-color: #084892 !important;
}

[type=radio].custom-radio {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

/* IMAGE STYLES */
[type=radio].custom-radio + div {
    cursor: pointer;
}

/* CHECKED STYLES */
[type=radio].custom-radio + div {
    background: #cddcec4f;
    outline: 1px solid #5285be30;
    padding: 15px;
    margin: 15px
}

[type=radio].custom-radio:checked + div {
    background: #cddcec4f;
    outline: 2px solid #5285be;
}


