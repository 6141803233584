.Tooltip {
    border: 1px solid #757575;
    box-shadow: none;
    position: absolute;
    padding: 2px;
    text-align: left;
    font-size: 12px;
    background: #0951a3;
    color: white;
    pointer-events: none;
    white-space: nowrap;
}
.Tooltip div{
    line-height: 15px;
}
